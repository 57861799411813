import { Directive, ElementRef, OnInit, inject, output } from '@angular/core';
import {debounceTime, filter, fromEvent, map, Subject, takeUntil} from 'rxjs';

@Directive({
  selector: '[scInfiniteScroll]',
  standalone: true,
})
export class InfiniteScrollDirective implements OnInit {
  private elementRef = inject(ElementRef);


  readonly infiniteScroll = output<void>();

  private scroll$ = fromEvent(this.elementRef.nativeElement, 'scroll');
  private unsubscribe$ = new Subject<void>();

  ngOnInit() {
    this.scroll$
      .pipe(
        debounceTime(200),
        map(() => this.elementRef.nativeElement),
        filter(({scrollTop, clientHeight, scrollHeight}) => scrollTop + clientHeight >= scrollHeight - 200),
        // distinctUntilChanged(),
        // switchMap(() => this.infiniteScroll),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.infiniteScroll.emit();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
